﻿export const translations = {
    global: {
        actions: {
            logout: 'Logout',
            login: 'Login',
            profile: 'Profile',
            save: 'Save',
            fullscreen: 'Toggle fullscreen',
        },
        messages: {
            pleaseWait: 'Please wait…',
            unknownError: 'Unknown error',
            errorWhileFetchingVersion: 'Error while fetching version',
        },
        banner: {
            preview: 'Preview',
        },
        version: 'Version: {0}', 
        errors: {
            fullscreenNotSupported: 'This browser doesn\'t support fullscreen functionality',
        },

    },
    pages: {
        login: {
            title: 'Publication Server Authentication',
            fields: {
                username: 'Username',
                password: 'Password',
            },
            loginFailed: 'Authentication failed :(',
            loginAction: 'Login',
        },
        publicationList: {
            list: {
                header: {
                    itemName: 'Name',
                    createdBy: 'Created by',
                    createdAt: 'Created at',
                    updatedBy: 'Updated by',
                    updatedAt: 'Updated at',
                },
                actions: {
                    download: 'Download',
                    delete: 'Delete',
                    openScenario: 'Open presentation',
                    copyScenarioURI: 'Copy presentation link',
                },
                noContent:
                    'No published ActiveGraf presentation found. Start by uploading from a presentation\'s Task Pane or drop an "actg" file here.',
                unnamedPresentation: 'Unnamed presentation',
            },
            actions: {
                upload: 'Upload',
            },
            dragAndDropDescription: 'Release the file start uploading',
            deletionDialog: {
                title: 'Publication deletion',
                description: 'Are you sure you want to delete this publication?',
                confirmAction: 'Confirm',
                cancelAction: 'Cancel',
            },
            invalidActgFile: 'Invalid `actg` file: {0}',
            publicationURICopied: 'Publication URL copied successfully.',
            passwordChangeDialog: {
                title: 'Publication password',
                description: 'Enter a password for the publication here, or leave it empty to clear the current password',
                confirmAction: 'Confirm',
                cancelAction: 'Cancel',
            },
            passwordProtectionTooltip: 'Password protection',
            invalidPassword: 'The password must contain other characters than spaces',
        },
        presentation: {
            presentationAlreadyRunning: 'Presentation already running in this browser',
            genericCommunicationError: 'Failed to communicate with the running AGCore',
            saveSucceeded: 'Save succeeded.',
            saveFailed: 'Save failed.',
            leaveConfirmationText: 'If you close the page any unsaved changes will be lost.',
            missingScenarioId: 'The scenario ID is missing.',
            missingScenario: 'The requested scenario was not found.',
            passwordPromptDialog: {
                title: 'Publication password',
                description: 'This publication is password protected',
                placeholder: 'Please enter the password here',
                confirmAction: 'Confirm',
                incorrectPassword: 'Incorrect password',
            },
        },
        containerLoading: {
            startingNewInstance: 'Starting a new ActiveGraf instance',
        },
    },
    loginFrame: {
        labels: {
            currentlyLoggedInUser: 'Currently logged in user: {0}',
            currentPublicationServer: 'Current Publication Server: {0}',
        },
        messages: {
            logoutFailed: 'Logout failed',
            loginSuccess: 'Successfully logged in',
            logoutSuccess: 'Successfully logged out',
        },
    },
};
